<template>
  <div>
    <b-card bg-variant="light" header="Residuos Sólidos" header-bg-variant="primary" header-text-variant="white" align="center" >
        
        <b-form @submit="modalEnviar" @reset="cancelar" align="left" id="formularioResiduos">
            <b-form-row>
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-ordinarios_peso"
                        label="Peso residuos ordinarios mens. (Ton)"
                        label-for="ordinarios_peso"
                    >
                        <b-form-input
                            id="ordinarios_peso"
                            v-model="form.ordinarios_peso"
                            type="number"
                            min="0"
                            max="10000"
                            step="0.1"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
               
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-reciclables_peso"
                        label="Peso residuos reciclables mens. (Ton)"
                        label-for="reciclables_peso"
                    >
                        <b-form-input
                            id="reciclables_peso"
                            v-model="form.reciclables_peso"
                            type="number"
                            min="0"
                            max="10000"
                            step="0.1"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-respel_epp"
                        label="Genera residuos peligrosos (EPPs)"
                        label-for="respel_epp"
                    >
                        <b-form-select 
                            id="respel_epp" 
                            v-model="form.respel_epp" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-respel_aceites"
                        label="Genera residuos peligrosos (Aceites)"
                        label-for="respel_aceites"
                    >
                        <b-form-select 
                            id="respel_aceites" 
                            v-model="form.respel_aceites" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-respel_bombillas"
                        label="Genera residuos peligrosos (Bombillas)"
                        label-for="respel_bombillas"
                    >
                        <b-form-select 
                            id="respel_bombillas" 
                            v-model="form.respel_bombillas" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-respel_solidos"
                        label="Genera residuos peligrosos (Solidos)"
                        label-for="respel_solidos"
                    >
                        <b-form-select 
                            id="respel_solidos" 
                            v-model="form.respel_solidos" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-respel_filtros"
                        label="Genera residuos peligrosos (Filtros)"
                        label-for="respel_filtros"
                    >
                        <b-form-select 
                            id="respel_filtros" 
                            v-model="form.respel_filtros" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-respel_otros"
                        label="Otro tipo de residuo"
                        label-for="respel_otros"
                    >
                        <b-form-input
                            id="respel_otros"
                            v-model="form.respel_otros"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                 <b-col cols="12" md="4">
                    <b-form-group
                        id="group-respel_peso"
                        label="Peso residuos peligrosos mens. (Kg)"
                        label-for="respel_peso"
                    >
                        <b-form-input
                            id="respel_peso"
                            v-model="form.respel_peso"
                            type="number"
                            min="0"
                            max="10000"
                            step="0.1"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-dique_contencion"
                        label="Para RESPEL liquidos, tiene dique contención"
                        label-for="dique_contencion"
                    >
                        <b-form-select 
                            id="dique_contencion" 
                            v-model="form.dique_contencion" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-kit_derrames"
                        label="Para RESPEL liquidos, tiene kit para derrames"
                        label-for="kit_derrames"
                    >
                        <b-form-select 
                            id="kit_derrames" 
                            v-model="form.kit_derrames" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-id_gestor_respel"
                        label="Identificador gestora RESPEL"
                        label-for="id_gestor_respel"
                    >
                        <b-form-select 
                            id="id_gestor_respel"
                            v-model="form.id_gestor_respel"
                            required
                            :options="mapearProveedores"
                            class="mb-3" 
                            :disabled="verDetalles?true:false"
                        >
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-id_dispos_final_respel"
                        label="Identificador empresa disposicion final del RESPEL"
                        label-for="id_dispos_final_respel"
                    >
                        <b-form-select 
                            id="id_dispos_final_respel"
                            v-model="form.id_dispos_final_respel"
                            required
                            :options="mapearProveedores"
                            class="mb-3" 
                            :disabled="verDetalles?true:false"
                        >
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-certificado_respel"
                        label="Tiene certificado de disposición final de residuos"
                        label-for="certificado_respel"
                    >
                        <b-form-select 
                            id="certificado_respel" 
                            v-model="form.certificado_respel" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4" v-if="form.certificado_respel==1">
                    <b-form-group
                        id="group-archivo_respel"
                        label="Archivo de la licencia RESPEL."
                        label-for="archivo_respel"
                    >
                        <b-form-file
                            id="archivo_respel"
                            :state="Boolean(form.archivo_respel)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenArchivoRespel"
                            v-if="!cargadoArchivoRespel"
                            :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if="cargadoArchivoRespel" @click="descargarPDF(form.archivo_respel, 'Archivo_Respel')">
                            Descargar
                        </b-button>
                    </b-form-group>

                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-registro_gener_respel"
                        label="Tiene registro de generador RESPEL"
                        label-for="registro_gener_respel"
                    >
                        <b-form-select 
                            id="registro_gener_respel" 
                            v-model="form.registro_gener_respel" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-clase_generador"
                        label="Clase de generador RESPEL"
                        label-for="clase_generador"
                    >
                        <b-form-select 
                            id="clase_generador" 
                            v-model="form.clase_generador" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">Pequeño Generador (10-100 kg/mes)</b-form-select-option>
                            <b-form-select-option value="1">Mediano Generador (100-1000 kg/mes)</b-form-select-option>
                            <b-form-select-option value="2">Gran Generador (=>1000 kg/mes)</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" class="text-right">
                    <b-button type="submit" variant="primary" class="mr-2" :disabled="verDetalles?true:false">
                        Guardar
                        </b-button>
                    <b-button type="reset" >Cancelar</b-button>
                </b-col>
            </b-form-row>

        </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {encontrarError} from '../../../contantesErrores';
  export default {
      name: 'ResiduosSolidosVigilanciaControl',
    data() {
      return {
        editar:false,
        cargadoArchivoRespel: false,
        form: {
            id: null,
            ordinarios_peso: null,
            reciclables_peso:null,
            respel_epp:null,
            respel_aceites:null,
            respel_bombillas: null,
            respel_solidos: null,
            respel_filtros: null,
            respel_otros: null,
            respel_peso: null,
            dique_contencion: null,
            kit_derrames: null,
            id_gestor_respel: null,
            id_dispos_final_respel: null,
            certificado_respel: null,
            archivo_respel: null,
            registro_gener_respel: null,
            clase_generador: null
        },
      }
    },
    async mounted(){
        if(this.visitaEnGestion.id){
            this.form.id=this.visitaEnGestion.id
            await this.cargarResiduosSolidosVC(this.visitaEnGestion.id)
        }
        try{
            await this.cargarProveedores()
        }catch(e){
            encontrarError(this, e.data)
        }
    },
    computed:{
        ...mapState('gestionarVisitas', ['verDetalles','visitaEnGestion']),
        ...mapState('gestionarVisitasVigilanciaControl', ['resultResiduosSolidosVC', 'residuosSolidosVCCargado' ]),
        ...mapState('consultaEmpresas', ['proveedoresServicio' ]),

        mapearProveedores(){
            let mapeadas = this.proveedoresServicio.map(item=>{
                let proveedor={
                    value: item.id_prov,
                    text: item.nombre
                }
                return proveedor
            })
            return mapeadas
        },
    },
    methods: {
        ...mapActions(['activarLoading']),
        ...mapActions('gestionarVisitasVigilanciaControl', ['crearResiduosSolidosVC', 'cargarResiduosSolidosVCById', 'editarResiduosSolidosVC']),
        ...mapActions('consultaEmpresas', ['cargarProveedores']),

        async cargarResiduosSolidosVC(id){
            this.activarLoading(true)
            try{
                await this.cargarResiduosSolidosVCById(id)
                console.log('esto es lo cargado', this.residuosSolidosVCCargado[0])
                if(this.residuosSolidosVCCargado.length>0){
                    this.editar=true
                    this.form=this.residuosSolidosVCCargado[0]
                    this.cargadoArchivoRespel =  this.form.archivo_respel?true:false
                }
                this.activarLoading(false)
            }catch(e){
                encontrarError(this, e.data)
            }
        },
        
        imagenArchivoRespel(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo)
        },

        crearBase64(file){
            if(file){
                let reader = new FileReader();
                reader.onload=(e)=>{
                    let archivo = e.target.result
                    let base = archivo.split('base64,')
                    let pdf = base[1]

                    this.form.archivo_respel = pdf;
                }
                reader.readAsDataURL(file)
            }else{
                this.form.archivo_respel = null;
            }
        },

        modalEnviar(event){
            event.preventDefault()
             this.$bvModal.msgBoxConfirm('Se van a guardar los datos de Residuos Sólidos, ¿Desea continuar?.', {
                title: 'Por favor confirme',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Continuar',
                cancelTitle: 'Cancelar',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        this.onSubmit()
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async onSubmit() {
            this.activarLoading(true)

            let dataGuardar = {
                residuosolido: this.form
            }

             try{
                 if(this.editar){
                     await this.editarResiduosSolidosVC(dataGuardar)
                 }else{
                     await this.crearResiduosSolidosVC(dataGuardar)
                 }
                if(this.resultResiduosSolidosVC.data.status==200){
                    this.activarLoading(false)
                    this.modalOk()
                }
            }catch(e){
                this.activarLoading(false)
                encontrarError(this, e.data)
            }
        },

        modalOk(){
            this.$bvModal.msgBoxOk('Los Datos fueron guardados correctamente.', {
                title: 'Confirmación',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    // this.$router.push({name: 'gestionVisita'})
                    this.cargarResiduosSolidosVC(this.visitaEnGestion.id)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        
        cancelar(){
            this.$bvModal.msgBoxOk('Se perderán los datos sin guardar.', {
                title: 'Cancelar',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    this.$router.push({name: 'gestionarVisitaVigilanciaControl'})
                })
                .catch(err => {
                    console.log(err)
                })
        }, 
        
        descargarPDF(pdf, nombre) {
            const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
            const fileName = `licencia_${nombre}.pdf`;
        
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
    }
  }
</script>

<style scoped>
    
</style>